import { useState } from 'react';
import emailjs from 'emailjs-com'; // Import EmailJS for sending emails
import './ContactUs.css'; // Import the CSS for styling the component

const ContactForm = () => {
  // State for form data, errors, and success message
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    location: '',
    linkedin: '',
    github: '',
    message: '', // Added message state for user input
  });

  const [errors, setErrors] = useState({}); // State for tracking validation errors
  const [successMessage, setSuccessMessage] = useState(''); // State for success message after submission

  // Handle changes in input fields
  const handleChange = (event) => {
    const { name, value } = event.target; // Destructure name and value from the event target
    setFormData((prevData) => ({
      ...prevData,
      [name]: value, // Update the respective field in form data
    }));
    
    // Clear any existing errors for the current field
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  // Validate form inputs before submission
  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = 'Name is required'; // Check if name is provided
    if (!formData.email) {
      newErrors.email = 'Email is required'; // Check if email is provided
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email format is invalid'; // Validate email format
    }
    if (!formData.phone) newErrors.phone = 'Phone number is required'; // Check if phone is provided
    if (!formData.location) newErrors.location = 'Location is required'; // Check if location is provided
    if (!formData.message) newErrors.message = 'Message is required'; // Validate message field
    return newErrors; // Return any validation errors found
  };

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    const validationErrors = validateForm(); // Validate the form inputs
    
    // If there are validation errors, update the errors state and exit
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    // Send the email using EmailJS
    emailjs.send('service_diexr19', 'template_5yltlec', formData, 'IfUpueXQ7RRs_sa8V')
      .then((response) => {
        console.log('Email successfully sent!', response.status, response.text);
        setSuccessMessage('Your message has been sent successfully!'); // Set the success message
        // Reset the form data after successful submission
        setFormData({
          name: '',
          email: '',
          phone: '',
          location: '',
          linkedin: '',
          github: '',
          message: '', // Reset the message field
        });
      })
      .catch((err) => {
        console.error('Failed to send email. Error:', err);
        setErrors({ api: 'Failed to send your message. Please try again later.' }); // Set API error message
      });
  };

  return (
    <div className="contact-form-container">
      <h2 className="contact-form-header">Get in touch with us!</h2>
      {successMessage && <p className="text-green-500">{successMessage}</p>} {/* Display success message */}
      {errors.api && <p className="text-red-500">{errors.api}</p>} {/* Display API error message */}
      <form onSubmit={handleSubmit} className="contact-form">
        {/* Input fields for the contact form */}
        <div>
          <input
            type="text"
            name="name"
            placeholder="Name"
            value={formData.name}
            onChange={handleChange}
            required
            className={`w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-400 ${errors.name ? 'border-red-500' : 'border-gray-300'}`}
          />
          {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>} {/* Display name error message */}
        </div>
        <div>
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            required
            className={`w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-400 ${errors.email ? 'border-red-500' : 'border-gray-300'}`}
          />
          {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>} {/* Display email error message */}
        </div>
        <div>
          <input
            type="tel"
            name="phone"
            placeholder="Phone"
            value={formData.phone}
            onChange={handleChange}
            required
            className={`w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-400 ${errors.phone ? 'border-red-500' : 'border-gray-300'}`}
          />
          {errors.phone && <p className="text-red-500 text-sm">{errors.phone}</p>} {/* Display phone error message */}
        </div>
        <div>
          <input
            type="text"
            name="location"
            placeholder="Location"
            value={formData.location}
            onChange={handleChange}
            required
            className={`w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-400 ${errors.location ? 'border-red-500' : 'border-gray-300'}`}
          />
          {errors.location && <p className="text-red-500 text-sm">{errors.location}</p>} {/* Display location error message */}
        </div>
        <div>
          <input
            type="url"
            name="linkedin"
            placeholder="LinkedIn URL"
            value={formData.linkedin}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-400 border-gray-300"
          />
        </div>
        <div>
          <input
            type="url"
            name="github"
            placeholder="GitHub URL"
            value={formData.github}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-400 border-gray-300"
          />
        </div>
        <div>
          <textarea
            name="message"
            placeholder="Your message..."
            value={formData.message}
            onChange={handleChange}
            required
            className={`w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-400 ${errors.message ? 'border-red-500' : 'border-gray-300'}`}
          />
          {errors.message && <p className="text-red-500 text-sm">{errors.message}</p>} {/* Display message error */}
        </div>
        <button
          type="submit"
          className="w-full px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600 transition duration-200"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default ContactForm; 

