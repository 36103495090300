// App.js

import React, { useState } from 'react'; // Importing React and useState hook
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom'; // Importing routing components
import Navbar from './components/Navbar'; // Importing Navbar component
import Home from './components/home.js'; // Importing Home component
import Calculator from './components/Calculator.js'; // Importing Calculator component
import StateResources from './components/StateResources.js'; // Importing StateResources component
import AboutUs from './components/AboutUs'; // Importing AboutUs component
import ContactUs from './components/ContactUs.js'; // Importing ContactUs component
import PrivacyPolicy from './components/PrivacyPolicy'; // Importing Privacy Policy component
import TermsOfService from './components/TermsOfService'; // Importing Terms of Service component
import Overlay from './components/Overlay'; // Importing Overlay component
import Banner from './components/Banner'; // Importing Banner component
import BlogTile from './components/BlogTile'; // Importing BlogTile component
import './App.css'; // Importing CSS styles

const App = () => {
  // State to track the active article for the overlay
  const [activeArticle, setActiveArticle] = useState(null);
  
  // State to manage visibility of the overlay
  const [isOverlayVisible, setOverlayVisible] = useState(false);

  // Array of blog articles with title, description, content, and image source
  const blogArticles = [
        {
            title: "10 Tips for First-Time Home Buyers",
            description: "Essential tips to help first-time buyers.",
            content: "Buying your first home is an exciting yet overwhelming experience. With so many factors to consider, it's easy to feel lost. To help you navigate the process, here are some key tips that will set you up for success.\n" +
                "\n" +
                "First and foremost, setting a realistic budget is crucial. Your budget should encompass not just the price of the home, but also additional costs like closing fees, taxes, insurance, and future maintenance. A good rule of thumb is to aim for a mortgage payment that doesn’t exceed 28-30% of your gross monthly income. Don't forget to consider how much you can afford to put down as a down payment, as this will directly affect your monthly payments.\n" +
                "\n" +
                "Once you have a budget, getting pre-approved for a mortgage is an essential next step. Pre-approval gives you a clear idea of how much you can borrow, which helps narrow your search and signals to sellers that you're a serious buyer. It’s important to remember that pre-approval is a more thorough process than pre-qualification, as it involves a lender reviewing your financials in detail.\n" +
                "\n" +
                "When searching for your home, don’t just focus on the property itself—research the neighborhood thoroughly. The location you choose can affect everything from your daily commute to long-term property value. Look at the area’s amenities, safety, school districts, and future development plans to ensure it fits your lifestyle and needs.\n" +
                "\n" +
                "Another critical step is to schedule a home inspection. Even if the home looks perfect, there may be hidden issues that could be costly to repair down the line. A professional inspection helps you identify potential problems early, allowing you to either negotiate repairs or reconsider the purchase.\n" +
                "\n" +
                "It's also important to understand the true cost of homeownership. In addition to your mortgage, you’ll be responsible for property taxes, maintenance, utilities, and possibly homeowners association (HOA) fees. Be prepared for these ongoing costs, and always budget for unexpected repairs.\n" +
                "\n" +
                "While it’s easy to get excited about a new home, don’t overstretch your finances. Lenders may approve you for a higher loan amount, but it's essential to stay within a comfortable range. A home should fit into your life financially without leaving you struggling to make ends meet.\n" +
                "\n" +
                "Many first-time buyers are unaware of the special programs and incentives available to them. Many local and state governments offer down payment assistance, tax credits, or reduced-interest loans for first-time buyers. Be sure to research these opportunities to reduce your financial burden.\n" +
                "\n" +
                "Working with a real estate agent can also make a huge difference. An experienced agent can help you find homes that meet your needs, negotiate on your behalf, and guide you through the paperwork. It's a valuable resource, especially for first-time buyers unfamiliar with the process.\n" +
                "\n" +
                "Lastly, be patient. The home-buying process can take time, and it’s easy to feel pressured to make a quick decision. Take your time to explore different properties, weigh your options, and make sure you’re confident in your choice. The right home will come along when the time is right.\n" +
                "\n" +
                "Buying a home is a big step, but with these tips in mind, you can approach the process with confidence and make a smart, informed decision. Happy house hunting!",
            imageSrc: `${process.env.PUBLIC_URL}/sold_sign.png` // Corrected image path
        },
        {
            title: "Understanding Mortgages",
            description: "A guide to different types of mortgages.",
            content: "A mortgage is one of the most important financial commitments you'll ever make, so understanding how it works is essential before you start shopping for a home. In simple terms, a mortgage is a loan used to buy property, typically paid back over a period of 15 to 30 years. The lender provides the funds, and you repay the loan with interest. But there’s much more to a mortgage than just borrowing money—let's break down the key aspects you need to understand.\n" +
                "\n" +
                "First, it’s crucial to know that there are different types of mortgage loans. The most common types are fixed-rate and adjustable-rate mortgages (ARMs). A fixed-rate mortgage means your interest rate stays the same throughout the life of the loan, providing stability and predictability for your monthly payments. An adjustable-rate mortgage has a rate that may change periodically based on market conditions, which means your payments could increase or decrease over time. Each type has its pros and cons, and your choice depends on your financial situation and how long you plan to stay in the home.\n" +
                "\n" +
                "Another important factor to consider is the down payment. This is the amount of money you pay upfront when purchasing a home. The larger your down payment, the less you need to borrow, which can reduce your monthly payments and the overall interest you pay. Traditionally, a 20% down payment is recommended, but many first-time buyers qualify for programs that allow lower down payments—sometimes as little as 3% to 5%.\n" +
                "\n" +
                "When shopping for a mortgage, you’ll come across terms like interest rates and annual percentage rates (APR). The interest rate is the cost of borrowing the loan expressed as a percentage. The APR includes the interest rate as well as any fees or additional costs associated with the loan, giving you a clearer picture of the total cost of the mortgage. Comparing both rates can help you determine which loan is the most affordable in the long run.\n" +
                "\n" +
                "Your credit score plays a significant role in the mortgage process. Lenders use your credit score to determine your risk level as a borrower. A higher credit score typically means better loan terms, including lower interest rates. If your score is lower, you might still qualify for a mortgage, but you may face higher rates or be required to make a larger down payment.\n" +
                "\n" +
                "One of the most important aspects of your mortgage is understanding your monthly payments. Your mortgage payment will consist of the principal (the loan amount you borrowed) and interest, along with property taxes, homeowners insurance, and possibly mortgage insurance. Property taxes and insurance are often bundled into your mortgage payment, so the lender collects these funds on your behalf and pays them to the respective authorities.\n" +
                "\n" +
                "There are also different mortgage terms available, the most common being 15-year and 30-year options. A 15-year mortgage allows you to pay off the loan faster, meaning you’ll pay less interest over the life of the loan. However, your monthly payments will be higher. A 30-year mortgage offers lower monthly payments, but you’ll pay more in interest over time. The term you choose will depend on your financial situation and goals.\n" +
                "\n" +
                "In addition, many lenders require private mortgage insurance (PMI) if you put down less than 20% on a conventional loan. PMI protects the lender in case you default on the loan, but it adds an extra cost to your monthly payment. Some government-backed loans, like FHA loans, may require a different type of mortgage insurance, but the principle is the same.\n" +
                "\n" +
                "Finally, it's essential to understand the concept of refinancing. Refinancing means replacing your current mortgage with a new one, typically to get a lower interest rate or adjust the loan term. Refinancing can help you save money in the long run, but it comes with costs, so it's important to carefully weigh the pros and cons before deciding.\n" +
                "\n" +
                "In conclusion, understanding mortgages is key to making an informed decision when buying a home. With the right knowledge about mortgage types, interest rates, down payments, and other key factors, you can navigate the homebuying process with confidence and find a loan that fits your financial goals. Remember, your mortgage is a long-term commitment, so take the time to explore your options and choose the best one for your needs.",
            imageSrc: `${process.env.PUBLIC_URL}/mortgage_rate.png` // Corrected image path
        },
        {
            title: "Finding the Right Neighborhood",
            description: "How to choose the perfect neighborhood.",
            content: "Choosing the right neighborhood is just as important as selecting the right home. After all, your neighborhood will impact everything from your daily commute to your long-term investment in the property. Whether you’re a first-time homebuyer or looking to move to a new area, understanding what to look for in a neighborhood can help you make the right choice for your lifestyle and future. Here’s what you need to consider.\n" +
                "\n" +
                "First, think about **location and convenience**. How far is the neighborhood from your workplace, schools, or other essential services like grocery stores, hospitals, and banks? A longer commute can add stress and time to your daily routine, so finding a location that offers easy access to your key destinations is important. Also, consider the neighborhood’s proximity to public transportation if you rely on buses or trains.\n" +
                "\n" +
                "Next, research the **safety and crime rates** of the area. A safe neighborhood is essential for peace of mind, especially if you’re planning to start a family. Many online resources and local police departments provide crime statistics that can give you a sense of how safe a neighborhood is. Don’t just rely on the numbers, though—take time to visit the area at different times of day to get a feel for the environment.\n" +
                "\n" +
                "Another key factor is the quality of the schools, even if you don’t have children yet. Strong public schools can improve your quality of life, and they are also a major selling point if you decide to resell your home later. Look up school ratings and read reviews from parents to gauge how well the schools in the area are performing. Good schools can also increase your home’s resale value, so this is something to consider even if you don’t have kids at the moment.\n" +
                "\n" +
                "Consider the neighborhood’s amenities and what’s available nearby. Is there a park for outdoor activities? Are there walking trails, gyms, or shopping centers within a short distance? Having these conveniences nearby can make your life easier and more enjoyable. Additionally, check out what future developments are planned for the area, as new infrastructure, businesses, or public services can affect the neighborhood’s appeal and value.\n" +
                "\n" +
                "If you’re planning on living in the area long-term, it’s important to understand the **future growth potential** of the neighborhood. Research whether the area is expected to grow in terms of population, businesses, and infrastructure. A growing neighborhood can lead to an increase in property values, making your home a better investment over time. On the other hand, buying in an area with limited growth could mean your property value stagnates or even decreases.\n" +
                "\n" +
                "Another aspect to consider is the community vibe. Different neighborhoods offer different lifestyles. Do you want a quiet, residential area with tree-lined streets and family-friendly parks, or a more vibrant, urban location with cafes, nightlife, and cultural events? Visit the area at different times to get a feel for the community. Are the neighbors friendly? Does the neighborhood seem well-maintained? Trust your instincts—finding a place that feels like home is just as important as any statistical data.\n" +
                "\n" +
                "In addition, pay attention to property values and the overall market conditions in the neighborhood. Research how home prices have changed in the area over the past few years, and talk to local real estate agents to get a sense of market trends. A neighborhood with rising property values can be a good investment, while an area with stagnating or declining prices may not be the best choice in the long term.\n" +
                "\n" +
                "Lastly, don’t overlook noise levels and traffic conditions. Heavy traffic or noise from nearby highways, train tracks, or construction can affect your day-to-day life. Visit the neighborhood during different hours of the day to get a sense of noise levels, and consider how close the neighborhood is to busy roads or commercial areas. If you value peace and quiet, this could be a dealbreaker.\n" +
                "\n" +
                "In conclusion, finding the right neighborhood involves balancing convenience, safety, community, and long-term potential. By considering factors like location, amenities, schools, and future growth, you can choose a neighborhood that not only suits your lifestyle but also serves as a solid investment for the future. Take your time, visit multiple areas, and trust your instincts to find the place that feels just right for you.",
            imageSrc: `${process.env.PUBLIC_URL}/neighborhood.png` // Corrected image path
        },
        {
          title: "Saving Money for a Down Payment",
          description: "Tips oh how to save for the big move.",
          content: 		"Buying a home is a significant milestone, but for many aspiring homeowners, saving for a down payment is one of the biggest hurdles. The good news is that with a clear plan, consistency, and some smart strategies, you can make your down payment goal a reality. The first step is to set a clear target amount. Down payments typically range from 3% to 20% of the home's price, depending on the type of loan you're pursuing. While a 20% down payment is ideal to avoid private mortgage insurance (PMI), certain programs like FHA loans allow for smaller down payments. Start by determining how much you need for the home you want and set a target that is both realistic and ambitious." +
          "" +
          "Next, create a budget and stick to it. Understanding where your money is going is essential for saving effectively. Review your income and expenses to figure out how much you can realistically save each month. The key is to cut back on unnecessary spending and redirect those funds toward your down payment. For example, you could reduce dining out, cancel unused subscriptions, or opt for more affordable entertainment. By identifying areas where you can cut back, you can free up more money for saving." +
          "" +
          "Opening a separate savings account is another important step. A dedicated account for your down payment helps keep you on track and prevents you from dipping into your savings for other expenses. Consider opening a high-yield savings or money market account to earn interest on your savings while it grows. This will also help you easily track your progress and keep your down payment fund organized." +
          "" +
          "Automating your savings is a powerful tool to ensure consistency. Set up an automatic transfer from your checking account to your down payment savings account each month. Even if you can only afford a small amount initially, over time, it will add up, and the process will feel less like a burden." +
          "" +
          "Sometimes, cutting back on small expenses isn't enough, and you may need to make bigger adjustments. For example, consider moving to a more affordable apartment or using public transportation instead of owning a car. These large changes can help you save significantly more each month, bringing you closer to your down payment goal faster." +
          "" +
          "Additionally, find ways to boost your income. If you're already trimming expenses, consider taking on a side hustle, freelance work, or a part-time job to provide extra savings. You could also declutter your home and sell items you no longer need, giving your down payment fund a one-time financial boost." +
          "" +
          "Take advantage of employer programs and first-time buyer incentives. Many employers offer down payment assistance, such as matching contributions or homebuyer education workshops. It’s also worth researching first-time homebuyer programs at the local, state, and federal levels. These programs can offer down payment assistance, lower interest rates, or other financial benefits to help make homeownership more accessible." +
          "" +
          "Lastly, avoid taking on new debt while saving for your down payment. New debt, such as credit card balances or personal loans, can hurt your credit score and impact your ability to secure a mortgage or get a favorable interest rate. Focus on paying down existing debt and building your savings, so you’re in a strong financial position when it’s time to apply for a mortgage." +
          "" +
          "In conclusion, saving for a down payment requires patience, discipline, and careful planning. By sticking to your budget, cutting back on expenses, and finding creative ways to boost your income, you'll be on your way to homeownership. Remember, every little bit adds up, so stay consistent and patient. Soon, you’ll be ready to make that down payment and unlock the door to your new home. Happy saving!",
          imageSrc: `${process.env.PUBLIC_URL}/downpayment.png` // Corrected image path
        },
        {
          title: "The Importance of Home Insurance",
          description: "Understanding home insurance and its benefits.",
          content: 		"Homeownership is one of the biggest investments you’ll ever make, and protecting that investment is crucial. Home insurance serves as a safety net, providing financial security in the event of accidents, damage, or theft. While it might seem like an added expense, having the right home insurance can save you from devastating financial loss when life takes an unexpected turn. Here's why home insurance is essential for homeowners." +
          "" +
          "Your home is more than just a place to live—it's where your personal belongings, memories, and valuables are kept. Whether it's a fire, burglary, or natural disaster, home insurance helps repair or replace your damaged property. Most policies cover a range of perils, such as fires, storms, vandalism, and theft. Without insurance, replacing these belongings out of pocket could be financially overwhelming." +
          "" +
          "Accidents can happen at any time, and if someone is injured on your property, you could be held liable for medical bills or legal costs. Home insurance typically includes liability coverage, which protects you if someone is injured on your property or if you accidentally cause damage to others’ property. This type of coverage can save you from costly legal fees or settlements." +
          "" +
          "In the event that your home becomes uninhabitable due to damage from a covered event, home insurance can cover additional living expenses such as hotel bills, meals, and transportation costs. This ensures you have a safe place to stay while your home is being repaired or rebuilt, without the added financial burden of finding temporary housing." +
          "" +
          "If you have a mortgage, your lender will likely require home insurance. Since the lender has a financial stake in your property, they want to ensure it’s protected. Home insurance gives them confidence that their investment will be safeguarded in the event of a disaster." +
          "" +
          "Perhaps one of the most valuable benefits of home insurance is the peace of mind it offers. Knowing that you're protected against unforeseen events can help reduce stress and allow you to focus on what matters most—your family and your future. Home insurance acts as a safety net, offering security during uncertain times." +
          "" +
          "In conclusion, home insurance isn’t just a smart financial decision; it’s a necessity. It protects your property, your belongings, and even your peace of mind. Whether you’re a first-time homeowner or have lived in your home for years, ensuring you have the right coverage is one of the best ways to safeguard your most valuable asset. Don’t wait for a disaster to happen—invest in home insurance today and rest easy knowing you’re covered.",
          imageSrc: `${process.env.PUBLIC_URL}/home_insurance.png` // Corrected image path
        },
        {
          title: "How to Maintain Your New Home",
          description: "Leearn how to retain your home's value over time.",
          content: 		"Regular home maintenance is essential to keep your home safe, comfortable, and functioning well, helping you avoid costly repairs and maintain your property’s value. It’s important to stay on top of various tasks throughout the year to ensure everything is in good shape. " +
          "" +
          "Start with monthly maintenance tasks like testing your smoke detectors to make sure they are working properly, and checking air filters to keep your HVAC system running efficiently. Inspect your plumbing for leaks and clean your garbage disposal to avoid odors. Each season requires specific checks to keep your home in top condition. In the spring, clean gutters and downspouts to prevent water damage, and inspect your roof for any damaged shingles. It’s also the time to service your air conditioner before the heat sets in and check outdoor faucets for any leaks. Additionally, inspect the foundation for cracks or settling. In summer, look for drafts around windows and doors and trim trees and shrubs away from your home to avoid damage. You should also maintain your lawn and garden, inspect your sump pump, and clean ceiling fans to keep everything in good working order. Fall is a great time to clean and inspect your chimney if you have a fireplace, winterize outdoor faucets and pipes, and check attic insulation for energy efficiency. You should also test your heating system before cold weather arrives and clear leaves from gutters and walkways to prevent clogs. " +
          "" +
          "During the winter months, make sure to check for ice dams on your roof, inspect your basement for moisture, and ensure your heating system and thermostat are functioning properly to avoid frozen pipes. Look for any leaks or dampness in the attic, as these can worsen in colder weather. Each year, set aside time for annual maintenance like deep cleaning your carpets, servicing your HVAC system, and checking your water heater. You should also inspect your garage door, test its operation, and check your foundation for any cracks or shifting. Every few years, repaint the exterior of your home, recaulk windows and doors to prevent drafts, and replace weather stripping for better energy efficiency. If you have a septic system, make sure it’s professionally pumped and inspected." +
          "" +
          "To stay organized, consider keeping a home maintenance log, tracking the dates of tasks so you know what needs to be done and when. Regular maintenance may seem overwhelming at first, but it can save you money and headaches in the long run, ensuring your home stays in great shape for years to come.",
          imageSrc: `${process.env.PUBLIC_URL}/maintenance_checklist.png` // Corrected image path
        },
      ];
    
       // Function to show the overlay with the selected article
  const showOverlay = (article) => {
    setActiveArticle(article);
    setOverlayVisible(true);
  };

  // Function to hide the overlay
  const hideOverlay = () => {
    setActiveArticle(null);
    setOverlayVisible(false);
  };

  return (
    <Router>
      <div className="App">
        <Navbar /> {/* Render Navbar component */}
        <Banner /> {/* Render Banner component */}
        <Routes>
          <Route path="/" element={<><Home /><BlogList blogArticles={blogArticles} showOverlay={showOverlay} /></>} />
          <Route path="/calculator" element={<Calculator />} />
          <Route path="/state-resources" element={<StateResources />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} /> {/* New Route for Privacy Policy */}
          <Route path="/terms-of-service" element={<TermsOfService />} /> {/* New Route for Terms of Service */}
        </Routes>
        
        {/* Conditional rendering of Overlay component */}
        {isOverlayVisible && <Overlay article={activeArticle} onClose={hideOverlay} />}
        
        {/* Footer with links to Privacy Policy and Terms of Service */}
        <footer className="footer">
          <Link to="/privacy-policy">Privacy Policy</Link>
          <span> | </span>
          <Link to="/terms-of-service">Terms of Service</Link>
          <div className="copyright">
            &copy; 2024 My First Time Home Buying Club. All rights reserved.
          </div>
        </footer>
      </div>
    </Router>
  );
};

// BlogList component to display the articles
const BlogList = ({ blogArticles, showOverlay }) => {
  return (
    <div className="blog-tiles">
      {blogArticles.map((article, index) => (
        <BlogTile 
          key={index} 
          title={article.title} 
          description={article.description} 
          imageSrc={article.imageSrc}
          onClick={() => showOverlay(article)} // Pass the article to the showOverlay function
        />
      ))}
    </div>
  );
};

export default App;