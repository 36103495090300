import React, { useEffect } from 'react'; // Import useEffect
import './PrivacyPolicy.css'; // Importing the CSS file for styling

const PrivacyPolicy = () => {
  useEffect(() => {
    document.title = "Privacy Policy"; // Set the document title to "Privacy Policy"
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <div className="privacy-policy"> {/* Main container for Privacy Policy content */}
      <h1>Privacy Policy</h1> {/* Main title of the Privacy Policy */}
      <p>Last updated: 12/3/2024</p> {/* Placeholder for last updated date */}
      <p>
        This Privacy Policy describes Our policies and procedures on the collection, use, and disclosure of Your information when You use our Service and tells You about Your privacy rights and how the law protects you.
      </p>

      {/* Section for Information We Collect */}
      <h2>Information We Collect</h2>
      <p>
        We collect several types of information for various purposes to provide and improve our Service to you, including:
      </p>
      <ul>
        <li>
          <strong>Personal Data:</strong> While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you.
        </li>
        <li>
          <strong>Usage Data:</strong> Usage Data is collected automatically, whether generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).
        </li>
      </ul>

      {/* Section for Use of Your Personal Data */}
      <h2>Use of Your Personal Data</h2>
      <p>The Company may use Personal Data for the following purposes:</p>
      <ul>
        <li>To provide and maintain our Service</li>
        <li>To notify you about changes to our Service</li>
        <li>To allow you to participate in interactive features of our Service when you choose to do so</li>
        <li>To provide customer support</li>
        <li>To gather analysis or valuable information so that we can improve our Service</li>
      </ul>

      {/* Section for Disclosure of Your Personal Data */}
      <h2>Disclosure of Your Personal Data</h2>
      <p>We may disclose personal information that we collect, or you provide:</p>
      <ul>
        <li>Compliance with a legal obligation</li>
        <li>Protect and defend the rights or property of the Company</li>
        <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
      </ul>

      {/* Section for Security of Your Personal Data */}
      <h2>Security of Your Personal Data</h2>
      <p>
        The security of your data is important to us, but remember that no method of transmission over the Internet or method of electronic storage is 100% secure.
      </p>

      {/* Section for Links to Other Websites */}
      <h2>Links to Other Websites</h2>
      <p>
        Our Service may contain links to other sites that are not operated by us. If you click on a third-party link, you will be directed to that third party's site.
      </p>

      {/* Section for Changes to This Privacy Policy */}
      <h2>Changes to This Privacy Policy</h2>
      <p>
        We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
      </p>
    </div>
  );
};

export default PrivacyPolicy;



