import React from 'react';

// Home component definition
const Home = () => {
    return (
        <div>
            {/* The "Go Home" button has been removed */}
        </div>
    );
};

// Export the Home component
export default Home;
