import React, { useState } from 'react'; // Import useState for managing state
import { Link } from 'react-router-dom'; // Importing Link for navigation
import './Navbar.css'; // Importing CSS for styling

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false); // State to manage the hamburger menu

    const toggleMenu = () => {
        setIsOpen(!isOpen); // Toggle the menu open/close
    };

    const handleLinkClick = () => {
        setIsOpen(false); // Close the menu when a link is clicked
    };

    return (
        <nav className="navbar"> {/* Main navigation container */}
            <Link to="/" className="navbar-title" onClick={handleLinkClick}> {/* Link for the title */}
                <h1>My First Home Club</h1> {/* Title of the website */}
            </Link>
            <div className="hamburger" onClick={toggleMenu}> {/* Hamburger menu */}
                <div></div>
                <div></div>
                <div></div>
            </div>
            <ul className={isOpen ? 'active' : ''}> {/* Toggle class based on state */}
                {/* Navigation Links */}
                <li><Link to="/" onClick={handleLinkClick}>Home</Link></li> {/* Link to Home page */}
                <li><Link to="/calculator" onClick={handleLinkClick}>Calculator</Link></li> {/* Link to Calculator page */}
                <li><Link to="/state-resources" onClick={handleLinkClick}>State Resources</Link></li> {/* Link to State Resources page */}
                <li><Link to="/about-us" onClick={handleLinkClick}>About Us</Link></li> {/* Link to About Us page */}
                <li><Link to="/contact-us" onClick={handleLinkClick}>Contact Us</Link></li> {/* Link to Contact Us page */}
            </ul>
        </nav>
    );
};

export default Navbar;

