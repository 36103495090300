import React from 'react';
import './BlogTile.css'; // Import the CSS file for styling the BlogTile component

// BlogTile component definition
const BlogTile = ({ title, description, onClick, imageSrc }) => {
    return (
        <div className="blog-tile" onClick={onClick}>
            {/* Display image for the blog tile */}
            <img 
                src={imageSrc} 
                alt={title} 
                className="blog-tile-image" 
            />
            {/* Display the blog title */}
            <h3>{title}</h3>
            {/* Display the blog description */}
            <p>{description}</p>
        </div>
    );
};

export default BlogTile; 
