import React from 'react';
import './AboutUs.css'; // Import CSS for styling

const AboutUs = () => {
  return (
    <div className="about-us">
      {/* Image of Tilly*/}
      <img 
        src={`${process.env.PUBLIC_URL}/Tilly.jpg`} 
        alt="Tilly the dog" 
        className="tilly-image" 
      />
      
      <div className="about-text">
        <h1>About Us</h1>
        
        <p>
          Hi, I’m Chris Tortorella, a 32-year-old senior at Wilmington University, pursuing a degree in Computer Science with a focus on Artificial Intelligence. I'm passionate about technology and the endless possibilities AI holds for the future. When I’m not buried in code, I enjoy unwinding with video games and exploring nature with my rescue dog, Tilly, who’s a great companion for my weekend hikes and outdoor adventures.
          
          In addition to my studies, I work as a Process Improvement Analyst at a major bank, focusing on risk management and website optimization. My role allows me to blend my technical skills with real-world problem-solving, helping the company streamline processes and enhance digital user experiences.
          
          I’m currently planning a move to Akron, Ohio, and am excited about the opportunities and new experiences that await. Whether it's furthering my career in tech or finding new places to explore with Tilly, I’m always looking for ways to grow both personally and professionally.
          
          Feel free to connect with me—I’m always open to sharing ideas, discussing AI, or chatting about the latest video game releases!
        </p>
      </div>
    </div>
  );
};

export default AboutUs;

