import React from 'react';
import './Banner.css'; // Import the CSS file for styling the banner

const Banner = () => {
    return (
        <div className="banner">
            {/* Image for the banner, loaded from the public URL */}
            <img 
                src={`${process.env.PUBLIC_URL}/home_banner.jpg`} 
                alt="Home Banner" 
                className="banner-image" 
            />
            {/* Overlay text for the banner */}
            <h1 className="banner-text">Welcome to the Club!</h1>
        </div>
    );
};

export default Banner;

