// components/TermsOfService.js

import React, { useEffect } from 'react'; // Importing React library and useEffect hook

const TermsOfService = () => {
  useEffect(() => {
    document.title = "Terms of Service"; // Set the document title to "Terms of Service"
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <div className="terms-of-service"> {/* Main container for Terms of Service content */}
      <h1>Terms of Service</h1> {/* Main title of the Terms of Service */}
      <p>Last updated: 12/3/2024</p> {/* Placeholder for the date of the last update */}
      <p>
        These terms govern your use of our service. By accessing or using our service, you agree to be bound by these terms.
      </p>

      {/* Section for Acceptance of Terms */}
      <h2>Acceptance of Terms</h2>
      <p>
        By accessing and using the Service, you accept and agree to be bound by the terms and conditions of this Agreement.
      </p>

      {/* Section for Changes to Terms */}
      <h2>Changes to Terms</h2>
      <p>
        We reserve the right, at our sole discretion, to modify or replace these Terms at any time.
      </p>

      {/* Section for Account Responsibility */}
      <h2>Account Responsibility</h2>
      <p>
        You are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer.
      </p>

      {/* Section for Termination */}
      <h2>Termination</h2>
      <p>
        We may terminate or suspend access to our Service immediately, without prior notice or liability, for any reason whatsoever.
      </p>

      {/* Section for Limitation of Liability */}
      <h2>Limitation of Liability</h2>
      <p>
        In no event shall the Company, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages.
      </p>

      {/* Section for Governing Law */}
      <h2>Governing Law</h2>
      <p>
        These Terms shall be governed by and construed in accordance with the laws of the Ohio, USA.
      </p>
    </div>
  );
};

export default TermsOfService;
