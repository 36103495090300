import React from 'react';
import './Overlay.css'; // 

const Overlay = ({ article, onClose }) => {
    if (!article) return null; // If no article is selected, return null

    const handleOverlayClick = (e) => {
        // If the click is on the overlay background (not on content), close the overlay
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    return (
        <div className="overlay" onClick={handleOverlayClick}>
            <div className="overlay-content">
                <button className="overlay-close" onClick={onClose} aria-label="Close overlay">
                    &times; {/* This is the HTML entity for the multiplication sign (×), often used as an "X" */}
                </button>
                <h2 className="overlay-title">{article.title}</h2> {/* Add class here */}
                <p>{article.content}</p>
            </div>
        </div>
    );
};

export default Overlay;

