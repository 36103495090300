import React, { useState } from 'react';
import './StateResources.css'; // Import the CSS file

// Data for state resources with display names
const stateResourcesData = {
    "Alabama": [{ url: "https://www.ahfa.com/homebuyer/", displayName: "AHFA Homebuyer" }],
    "Alaska": [{ url: "https://www.akhousingfinance.com/first-time-homebuyer", displayName: "AK Housing Finance" }],
    "Arizona": [{ url: "https://www.azhousing.gov/first-time-homebuyer-program", displayName: "AZ Homebuyer Program" }],
    "Arkansas": [{ url: "https://www.arkansas.gov/dfa/home_page/homebuyer.html", displayName: "Arkansas Homebuyer" }],
    "California": [{ url: "https://www.hcd.ca.gov/grants-funding/homebuyer-assistance", displayName: "CA Homebuyer Assistance" }],
    "Colorado": [{ url: "https://www.chfainfo.com/", displayName: "CHF Info" }],
    "Connecticut": [{ url: "https://portal.ct.gov/DOS/Consumer-Protection/Programs/First-Time-Homebuyer-Program", displayName: "CT First-Time Homebuyer" }],
    "Delaware": [{ url: "https://delawarehomeownership.com/", displayName: "Delaware Home Ownership" }],
    "Florida": [{ url: "https://www.floridahousing.org/", displayName: "Florida Housing" }],
    "Georgia": [{ url: "https://www.dca.ga.gov/safe-affordable-housing/homebuyer-programs", displayName: "GA Homebuyer Programs" }],
    "Hawaii": [{ url: "https://hawaiihousingfinance.com/", displayName: "Hawaii Housing Finance" }],
    "Idaho": [{ url: "https://www.idahohousing.com/first-time-home-buyers/", displayName: "Idaho Housing" }],
    "Illinois": [{ url: "https://ihda.org/", displayName: "Illinois Housing Development Authority" }],
    "Indiana": [{ url: "https://www.in.gov/ihcda/homebuyers/", displayName: "Indiana Homebuyers" }],
    "Iowa": [{ url: "https://iowafinance.com/first-time-homebuyers/", displayName: "Iowa Finance" }],
    "Kansas": [{ url: "https://www.kshousingcorp.org/", displayName: "Kansas Housing Corp" }],
    "Kentucky": [{ url: "https://www.kyhousing.org/homebuyers", displayName: "Kentucky Homebuyers" }],
    "Louisiana": [{ url: "https://www.lhfa.state.la.us/", displayName: "Louisiana Housing" }],
    "Maine": [{ url: "https://www.mainehousing.org/", displayName: "Maine Housing" }],
    "Maryland": [{ url: "https://dhcd.maryland.gov/Residents/Pages/homeownership.aspx", displayName: "Maryland Homeownership" }],
    "Massachusetts": [{ url: "https://www.mass.gov/housing-finance-agency", displayName: "Massachusetts Housing Finance" }],
    "Michigan": [{ url: "https://www.michigan.gov/mshda", displayName: "Michigan State Housing Development Authority" }],
    "Minnesota": [{ url: "https://www.mnhousing.gov/", displayName: "Minnesota Housing" }],
    "Mississippi": [{ url: "https://www.mshp.ms.gov/", displayName: "Mississippi Home Purchase" }],
    "Missouri": [{ url: "https://www.mhdc.com/", displayName: "Missouri Housing Development Commission" }],
    "Montana": [{ url: "https://housing.mt.gov/", displayName: "Montana Housing" }],
    "Nebraska": [{ url: "https://www.nifa.org/", displayName: "Nebraska Investment Finance Authority" }],
    "Nevada": [{ url: "https://www.housing.nv.gov/", displayName: "Nevada Housing Division" }],
    "New Hampshire": [{ url: "https://www.nhhfa.org/", displayName: "New Hampshire Housing" }],
    "New Jersey": [{ url: "https://www.njhousing.gov/", displayName: "New Jersey Housing" }],
    "New Mexico": [{ url: "https://www.nmhfa.org/", displayName: "New Mexico Mortgage Finance Authority" }],
    "New York": [{ url: "https://hcr.ny.gov/homebuyer-programs", displayName: "New York Homebuyer Programs" }],
    "North Carolina": [{ url: "https://www.nchfa.com/", displayName: "North Carolina Housing Finance Agency" }],
    "North Dakota": [{ url: "https://www.ndhfa.org/", displayName: "North Dakota Housing Finance Agency" }],
    "Ohio": [{ url: "https://www.ohiohome.org/", displayName: "Ohio Housing Finance Agency" }],
    "Oklahoma": [{ url: "https://ohfa.org/", displayName: "Oklahoma Housing Finance Agency" }],
    "Oregon": [{ url: "https://www.oregon.gov/ohcs/Pages/homeownership.aspx", displayName: "Oregon Homeownership" }],
    "Pennsylvania": [{ url: "https://phfa.org/", displayName: "Pennsylvania Housing Finance Agency" }],
    "Rhode Island": [{ url: "http://www.rhodeislandhousing.org/", displayName: "Rhode Island Housing" }],
    "South Carolina": [{ url: "https://www.schousing.com/", displayName: "South Carolina Housing" }],
    "South Dakota": [{ url: "https://sdhda.org/", displayName: "South Dakota Housing Development Authority" }],
    "Tennessee": [{ url: "https://thda.org/", displayName: "Tennessee Housing Development Agency" }],
    "Texas": [{ url: "https://www.thc.texas.gov/", displayName: "Texas Housing Commission" }],
    "Utah": [{ url: "https://housing.utah.gov/", displayName: "Utah Housing" }],
    "Vermont": [{ url: "https://www.vhfa.org/", displayName: "Vermont Housing Finance Agency" }],
    "Virginia": [{ url: "https://www.vhda.com/", displayName: "Virginia Housing Development Authority" }],
    "Washington": [{ url: "https://www.wshfc.org/", displayName: "Washington State Housing Finance Commission" }],
    "West Virginia": [{ url: "https://www.wvhdf.com/", displayName: "West Virginia Housing Development Fund" }],
    "Wisconsin": [{ url: "https://www.wisconsin.gov/Pages/Welcome.aspx", displayName: "Wisconsin Government" }],
    "Wyoming": [{ url: "https://www.wyomingcda.com/", displayName: "Wyoming Community Development Authority" }]
};

const StateResources = () => {
    const [stateInput, setStateInput] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility

    const handleStateChange = (event) => {
        setStateInput(event.target.value);
        setErrorMessage(''); // Clear error message on new input
    };

    const handleSubmit = (event) => {
        event.preventDefault(); // Prevent default form submission behavior
        const formattedState = stateInput.trim(); // Remove extra spaces

        if (stateResourcesData[formattedState]) {
            setSelectedState(formattedState); // Set the selected state
            setIsModalOpen(true); // Open the modal
        } else {
            setErrorMessage('Please enter a valid state name.'); // Set error message for invalid state
            setSelectedState(''); // Reset selected state if invalid
        }
    };

    const closeModal = () => {
        setIsModalOpen(false); // Close the modal
        setSelectedState(''); // Reset selected state
        setStateInput(''); // Clear the state input
    };

    return (
        <div className="state-resources-container"> {/* Main container for the component */}
            <h1>State Resources</h1> {/* Title of the section */}
            <p>
                Enter your state to discover available resources such as financial assistance, local grants, and homebuying education in your state.
            </p>
            <form onSubmit={handleSubmit}> {/* Form for state input */}
                <label htmlFor="state-input">Enter your state name:</label>
                <input
                    type="text"
                    id="state-input"
                    value={stateInput}
                    onChange={handleStateChange}
                    placeholder="e.g., Alabama"
                />
                <button type="submit">Submit</button> {/* Submit button */}
            </form>

            {errorMessage && <p className="error-message">{errorMessage}</p>} {/* Error message display */}

            {/* Modal for displaying resources */}
            {isModalOpen && (
                <div className="modal-overlay"> {/* Overlay for modal */}
                    <div className="modal-content"> {/* Content of the modal */}
                        <h2>Resources for {selectedState}</h2>
                        <ul>
                            {stateResourcesData[selectedState].map((resource, index) => (
                                <li key={index} className="resource-text"> {/* Apply the resource-text class */}
                                    <a href={resource.url} target="_blank" rel="noopener noreferrer">
                                        {resource.displayName}
                                    </a>
                                </li>
                            ))}
                        </ul>
                        <button className="close-modal" onClick={closeModal}>Close</button> {/* Close button */}
                    </div>
                </div>
            )}
        </div>
    );
};

export default StateResources;
