import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Calculator.css'; 

const Calculator = () => {
    const [income, setIncome] = useState('');
    const [debt, setDebt] = useState('');
    const [dtiRatio, setDTIRatio] = useState(null);
    const [dtiHistory, setDTIHistory] = useState(() => {
        const savedHistory = localStorage.getItem('dtiHistory');
        return savedHistory ? JSON.parse(savedHistory) : [];
    });
    const [dtiError, setDTIError] = useState('');
    const [mortgageAmount, setMortgageAmount] = useState('');
    const [interestRate, setInterestRate] = useState('');
    const [loanTerm, setLoanTerm] = useState(30); // Default loan term in years
    const [monthlyPayment, setMonthlyPayment] = useState(null);
    const [mortgageHistory, setMortgageHistory] = useState(() => {
        const savedHistory = localStorage.getItem('mortgageHistory');
        return savedHistory ? JSON.parse(savedHistory) : [];
    });
    const [mortgageError, setMortgageError] = useState('');
    const [currentInterestRate, setCurrentInterestRate] = useState(null);
    const [loading, setLoading] = useState(true);
    const API_KEY = 'NJIzE57TEsfPdZTc/johag==UV7FyIwK4rU3gO8S'; // Your API key for fetching mortgage rates

    // Set the document title
    useEffect(() => {
        document.title = "Calculator"; // Set the document title to "Calculator"
    }, []);

    // Helper function for sanitizing numeric inputs
    const sanitizeInput = (input) => {
        if (typeof input === 'number') {
            return input.toString(); // Convert number to string
        }
        if (typeof input === 'string') {
            return input.replace(/[^0-9.]/g, ''); // Allow only numbers and decimal points
        }
        return ''; // Return empty string if input is not a string or number
    };

    // Fetch current mortgage interest rates from API on component mount
    useEffect(() => {
        const fetchCurrentInterestRate = async () => {
            setLoading(true); // Set loading state
            try {
                const response = await axios.get('https://api.api-ninjas.com/v1/mortgagerate', {
                    headers: {
                        'X-Api-Key': API_KEY // Use the API key in headers
                    }
                });
                
                // Ensure we have data to work with
                if (response.data && response.data.length > 0) {
                    const latestRate = response.data[0].rate; // Access the latest rate
                    if (latestRate !== undefined) {
                        setCurrentInterestRate(latestRate);
                        setInterestRate(latestRate.toString()); // Update interest rate in state
                    } else {
                        handleRateError('Rate data not found.');
                    }
                } else {
                    handleRateError('No mortgage rate data found in response.');
                }
            } catch (error) {
                handleRateError('Error fetching interest rate: ' + error.message);
            } finally {
                setLoading(false); // Reset loading state
            }
        };

        fetchCurrentInterestRate();
    }, []); // Run only once on mount

    // Error handler for interest rate fetch
    const handleRateError = (message) => {
        console.error(message);
        setCurrentInterestRate(null); // Reset current interest rate on error
    };

    // Function to calculate Debt-to-Income (DTI) ratio
    const calculateDTI = () => {
        setDTIError(''); // Reset DTI error message
        const sanitizedIncome = sanitizeInput(income);
        const sanitizedDebt = sanitizeInput(debt);
        const incomeNum = parseFloat(sanitizedIncome);
        const debtNum = parseFloat(sanitizedDebt);

        // Validate income input
        if (isNaN(incomeNum) || sanitizedIncome === '') {
            setDTIError('Please enter a valid numeric income.');
            return;
        }
        // Validate debt input
        if (isNaN(debtNum) || sanitizedDebt === '') {
            setDTIError('Please enter a valid numeric debt.');
            return;
        }

        // Further validation for income and debt
        if (incomeNum <= 0) {
            setDTIError('Income must be a positive number.');
            return;
        }
        if (debtNum < 0) {
            setDTIError('Debt cannot be a negative number.');
            return;
        }

        // Calculate DTI ratio
        const ratio = (debtNum / incomeNum) * 100;
        setDTIRatio(ratio.toFixed(2));

        // Save to DTI history only if calculation is successful
        const newEntry = { income: incomeNum, debt: debtNum, ratio: ratio.toFixed(2) };
        const updatedDTIHistory = [...dtiHistory, newEntry];
        setDTIHistory(updatedDTIHistory);
        localStorage.setItem('dtiHistory', JSON.stringify(updatedDTIHistory)); // Save history to local storage
    };

    // Function to calculate monthly mortgage payment
    const calculateMortgage = () => {
        setMortgageError(''); // Reset mortgage error message
        const sanitizedMortgageAmount = sanitizeInput(mortgageAmount);
        const sanitizedInterestRate = sanitizeInput(interestRate);
        const sanitizedLoanTerm = sanitizeInput(loanTerm);
        
        const principal = parseFloat(sanitizedMortgageAmount);
        const interest = parseFloat(sanitizedInterestRate) || currentInterestRate; // Use current interest rate if interestRate is empty
        const term = parseInt(sanitizedLoanTerm, 10);

        // Validate mortgage inputs
        if (isNaN(principal) || sanitizedMortgageAmount === '') {
            setMortgageError('Please enter a valid numeric loan amount.');
            return;
        }
        if (isNaN(interest) || sanitizedInterestRate === '') {
            setMortgageError('Please enter a valid numeric interest rate or use the current interest rate.');
            return;
        }
        if (isNaN(term) || sanitizedLoanTerm === '') {
            setMortgageError('Please enter a valid numeric loan term.');
            return;
        }

        // Further validation for principal, interest and term
        if (principal <= 0) {
            setMortgageError('Loan amount must be a positive number.');
            return;
        }
        if (interest < 0) {
            setMortgageError('Interest rate cannot be negative.');
            return;
        }
        if (term <= 0) {
            setMortgageError('Loan term must be a positive number.');
            return;
        }

        // Calculate monthly payment using the formula
        const calculatedInterest = interest / 100 / 12;
        const calculatedPayments = term * 12;
        const x = Math.pow(1 + calculatedInterest, calculatedPayments);
        const monthly = (principal * x * calculatedInterest) / (x - 1);

        // Check if the result is a finite number
        if (isFinite(monthly)) {
            setMonthlyPayment(monthly.toFixed(2));

            // Save to mortgage history only if calculation is successful
            const newEntry = { mortgageAmount: principal, interestRate: interest, loanTerm: term, monthly: monthly.toFixed(2) };
            const updatedMortgageHistory = [...mortgageHistory, newEntry];
            setMortgageHistory(updatedMortgageHistory);
            localStorage.setItem('mortgageHistory', JSON.stringify(updatedMortgageHistory)); // Save history to local storage
        } else {
            setMortgageError('An error occurred while calculating the monthly payment.');
            setMonthlyPayment(null);
        }
    };

    // Clear history function
    const clearHistory = (type) => {
        if (type === 'dti') {
            setDTIHistory([]); // Clear DTI history
            localStorage.removeItem('dtiHistory'); // Remove DTI history from local storage
        } else if (type === 'mortgage') {
            setMortgageHistory([]); // Clear mortgage history
            localStorage.removeItem('mortgageHistory'); // Remove mortgage history from local storage
        }
    };

    return (
        <div className="calculator-container">
            <h3>Financial Calculators</h3>
            <div className="calculators-wrapper">
                {/* Debt-to-Income Ratio Calculator */}
                <div className="calculator-box">
                    <h3>Debt-to-Income Ratio Calculator</h3>
                    <p>Enter your monthly income and total monthly debt payments to calculate your Debt-to-Income Ratio (DTI).</p>
                    <label>
                        Monthly Income ($):
                        <input
                            type="number"
                            placeholder="Enter your monthly income"
                            value={income}
                            onChange={(e) => setIncome(e.target.value)}
                            style={{ color: 'black', backgroundColor: 'white' }} // Inline styles
                        />
                    </label>
                    <label>
                        Monthly Debt Payments ($):
                        <input
                            type="number"
                            placeholder="Enter your monthly debt payments"
                            value={debt}
                            onChange={(e) => setDebt(e.target.value)}
                            style={{ color: 'black', backgroundColor: 'white' }} // Inline styles
                        />
                    </label>
                    <button onClick={calculateDTI} className="calculate-button">Calculate DTI</button>
                    {dtiError && <p className="error-message">{dtiError}</p>}
                    {dtiRatio !== null && (
                        <p>Your Debt-to-Income Ratio is: {dtiRatio}%</p>
                    )}
                    <div className="history-box">
                        <h4>DTI Calculation History</h4>
                        {dtiHistory.length > 0 ? (
                            <ul>
                                {dtiHistory.map((entry, index) => (
                                    <li key={index}>
                                        Income: ${entry.income}, Debt: ${entry.debt}, DTI: {entry.ratio}%
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p>No history available.</p>
                        )}
                    </div>
                    <button onClick={() => clearHistory('dti')} className="clear-history-button">Clear DTI History</button>
                </div>
    
                {/* Mortgage Payment Calculator */}
                <div className="calculator-box">
                    <h3>Mortgage Payment Calculator</h3>
                    <p>Enter the loan amount, interest rate, and loan term to calculate your monthly mortgage payment.</p>
                    <label>
                        Loan Amount ($):
                        <input
                            type="number"
                            placeholder="Enter the loan amount"
                            value={mortgageAmount}
                            onChange={(e) => setMortgageAmount(e.target.value)}
                            style={{ color: 'black', backgroundColor: 'white' }} // Inline styles
                        />
                    </label>
                    <label>
                        Interest Rate (%):
                        <input
                            type="number"
                            placeholder="Enter the interest rate"
                            value={interestRate}
                            onChange={(e) => setInterestRate(e.target.value)}
                            style={{ color: 'black', backgroundColor: 'white' }} // Inline styles
                        />
                    </label>
                    <label>
                        Loan Term (Years):
                        <input
                            type="number"
                            placeholder="Enter the loan term in years"
                            value={loanTerm}
                            onChange={(e) => setLoanTerm(e.target.value)}
                            style={{ color: 'black', backgroundColor: 'white' }} // Inline styles
                        />
                    </label>
                    <button onClick={calculateMortgage} className="calculate-button">Calculate Payment</button>
                    {mortgageError && <p className="error-message">{mortgageError}</p>}
                    {monthlyPayment !== null && (
                        <p>Your Monthly Mortgage Payment is: ${monthlyPayment}</p>
                    )}
                    <div className="history-box">
                        <h4>Mortgage Calculation History</h4>
                        {mortgageHistory.length > 0 ? (
                            <ul>
                                {mortgageHistory.map((entry, index) => (
                                    <li key={index}>
                                        Amount: ${entry.mortgageAmount}, Rate: {entry.interestRate}%, Term: {entry.loanTerm} years, Monthly Payment: ${entry.monthly}
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p>No history available.</p>
                        )}
                    </div>
                    <button onClick={() => clearHistory('mortgage')} className="clear-history-button">Clear Mortgage History</button>
                </div>
            </div>
            {/* Display Current Interest Rate */}
            <div className="current-interest-rate">
                {loading ? (
                    <p>Loading current interest rate...</p>
                ) : (
                    <p>Current Mortgage Interest Rate: {currentInterestRate ? `${currentInterestRate}%` : 'Data not available'}</p>
                )}
            </div>
        </div>
    );
};

export default Calculator;





